import React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import './TextComparison.css';
import { Button, ButtonGroup, Col, Container, Form, Nav, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import ReactDiffViewer from 'react-diff-viewer';
import TextAreaWithLineNumber from 'text-area-with-line-number';
import MyTextAreaWithLineNumber from '../MyTextAreaWithLineNumber/MyTextAreaWithLineNumber';

const TextComparison = () => {
  const [oldText, setOldText] = useState('Old Text');
  const [newText, setNewText] = useState('New Text');

  const [oldTextDiff, setOldTextDiff] = useState('');
  const [newTextDiff, setNewTextDiff] = useState('');

  const compareTexts = ()=>{
    setOldTextDiff(oldText);
    setNewTextDiff(newText);
  }

  const setTextAndRefreshDiff = (option, text)=>{
    if(option === "old"){
      setOldText(text);
    } else {
      setNewText(text);
    }
    setOldTextDiff('');
    setNewTextDiff('');
  }
  return (
    <div className="TextComparison">
      <Container>
        <Stack gap={2}>

          <Stack direction="horizontal" gap={2}>
            <Form.Control style={{border: '2px solid rgba(0, 0, 0, 0.5)'}} as="textarea" rows={10} placeholder="Old Text ..." value={oldText} onChange={(e)=> setTextAndRefreshDiff('old', e.target.value)} />
            <Form.Control style={{border: '2px solid rgba(0, 0, 0, 0.5)'}} as="textarea" rows={10} placeholder="New Text ..." value={newText} onChange={(e)=> setTextAndRefreshDiff('new', e.target.value)} />
          </Stack>
          <Stack direction="horizontal" style={{display: 'flex', justifyContent: 'center'}}>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Compare the two texts.</Tooltip>}>
                    <Button size="lg" onClick={compareTexts} variant="outline-primary">Compare Texts</Button>
                  </OverlayTrigger>
          </Stack>
          <Stack>
            {(oldTextDiff || newTextDiff) &&
              <div>
                <i>
                  <p>
                  Powered by <a href="https://github.com/praneshr/react-diff-viewer">react-diff-viewer</a>
                  </p>
                </i>
                <div style={{border: '1px solid rgba(0, 0, 0, 0.5)'}} >
                  <ReactDiffViewer oldValue={oldTextDiff} newValue={newTextDiff} splitView={true} />
                </div>
                
              </div>
              
            }
          </Stack>

            {/* <MyTextAreaWithLineNumber text={firstText} />
            <MyTextAreaWithLineNumber text={secondText} /> */}

            {/* <div style={{border: '2px solid rgba(0, 0, 0, 0.5)'}}>
                    <TextAreaWithLineNumber value={firstText} onChange={(e)=> setFirstText(e.target.value)} />
            </div>
            <div style={{border: '2px solid rgba(0, 0, 0, 0.5)'}}>
                    <TextAreaWithLineNumber value={secondText} onChange={(e)=> setSecondText(e.target.value)} />
            </div> */}

        </Stack>

      </Container>
      
    </div>
  );
};

TextComparison.propTypes = {};

TextComparison.defaultProps = {};

export default TextComparison;



 

