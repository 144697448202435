import React from 'react';
import PropTypes from 'prop-types';
import './TextManipulator.css';
import { Button, ButtonGroup, Col, Container, Form, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import {useState} from 'react';
import beautify from 'xml-beautifier';
import {isMobile} from 'react-device-detect';
import TextAreaWithLineNumber from 'text-area-with-line-number';
import yaml from 'js-yaml';




const TextManipulator = () => {
  const lineSeparator = '\n';

  const [text, setText] = useState('');

  const getUserInputAsLines = () => {
    return text.split(lineSeparator);
  }
  
  const sort = () => {
    let whitespaceRegex = /^\s*$/
    let sortedLines = getUserInputAsLines().filter(t => !whitespaceRegex.test(t)).sort();
    setText(sortedLines.join(lineSeparator));
  }
  
  const reverse = ()=>{
    let reverseLines = getUserInputAsLines().reverse();
    setText(reverseLines.join(lineSeparator));
  }
  
  const dedup = ()=>{
    let set = new Set(getUserInputAsLines());
    setText(Array.from(set).join(lineSeparator));
  }
  
  const beautifyJson = () => {
    setText(JSON.stringify(JSON.parse(text), null, 2));
  }
  
  const beautifyXml = () => {
    setText(beautify(text));
  }

  const base64Encode = () => {
    setText(btoa(text));
  }

  const base64Decode = () => {
    setText(atob(text));
  }

  const urlPercentEncode = () => {
    setText(encodeURI(text));
  }

  const urlPercentDecode = () => {
    setText(decodeURI(text));
  }

  const sortYaml = () => {
    try {
      const doc = yaml.dump(yaml.load(text), { sortKeys: true });
      setText(doc)
    } catch (e) {
      console.log(e);
    }
  }
  /*




  */
 
  let buttonTxtSize = 25;
  let buttonGroupStackDirection = "vertical";

  if(!isMobile){
    buttonGroupStackDirection = "horizontal";
  }

  return (
    <div className="TextManipulator">
      {/* Does this mean that for devices xxl and below we use fluid?
      and for anything above don't? */}
      <Container>
        <Stack direction={buttonGroupStackDirection} gap={3}>
            <ButtonGroup>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Sort text lines in ascending order.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={sort} variant="outline-primary">sort asc</Button>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Reverse the text lines.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={reverse} variant="outline-primary">reverse</Button>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Remove duplicate lines of text.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={dedup} variant="outline-primary">dedup</Button>
              </OverlayTrigger>
            </ButtonGroup>

            <ButtonGroup>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Format json text.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={beautifyJson} variant="outline-primary">pretty &#123;&#125;</Button>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Format xml text.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={beautifyXml} variant="outline-primary">&lt;/&gt;</Button>
              </OverlayTrigger>
            </ButtonGroup>

            <ButtonGroup>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Base64 encode text.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={base64Encode} variant="outline-primary">base64 enc</Button>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Base64 decode Base64 encoded text.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={base64Decode} variant="outline-primary">dec</Button>
              </OverlayTrigger>
            </ButtonGroup>

            <ButtonGroup>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Url encode url text.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={urlPercentEncode} variant="outline-primary">url % enc</Button>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Url decode some url encoded text.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={urlPercentDecode} variant="outline-primary">dec</Button>
              </OverlayTrigger>
            </ButtonGroup>

            <ButtonGroup>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Sorts given yaml text in lexicographic order by the key. Note this sorting can also work for a simple property files.</Tooltip>}>
                <Button style={{fontSize: buttonTxtSize}} size="lg" onClick={sortYaml} variant="outline-primary">sort yaml</Button>
              </OverlayTrigger>
            </ButtonGroup>
        </Stack>
        <br/>
        <Stack>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <div style={{border: '2px solid rgba(0, 0, 0, 0.5)'}}>
                <TextAreaWithLineNumber height='75vh' placeholder="Text ..." value={text} onChange={(e)=> setText(e.target.value)} />
              </div>

            </Form.Group>
        </Stack>
      </Container>

      <br />
      <br />
    </div>
  );
}

TextManipulator.propTypes = {};

TextManipulator.defaultProps = {};

export default TextManipulator;
