import React from 'react';
import PropTypes from 'prop-types';
import './About.css';
import { Container, Row } from 'react-bootstrap';

const About = () => (
  <div className="About">
    <Container>
        <Row>
          <p>Welcome to TextTools!</p>
          <br />
          <p>This application is primarily (but not exclusively) for technical folks such as developers, testers, etc.</p>
          <br />
          <p>As a developer I often find myself needing certain text functions that I couldn't find in a onestop shop application
            that didn't sacrifice security. For example, maybe I was preparing a rest request payload and needed to base64 encode a username
            and password for basic authentication. This application won't make any ajax calls with any text data inputted. All the functionality
            would be executed via javascript in the browser on your own machine.</p>
          <br />
          <p>If you have any suggestions for more text functions open an issue or PR here <a href="https://github.com/fgharo/text-tools.git">Github</a></p>
        </Row>
      
    </Container>

  </div>
);

About.propTypes = {};

About.defaultProps = {};

export default About;
