import React from 'react';
import PropTypes from 'prop-types';
import './OtherResources.css';
import { Container, Row } from 'react-bootstrap';

const OtherResources = () => (
  <div className="OtherResources">
    <Container>
        <Row>
          <p>Other useful apps:</p>
          <a href="https://regexr.com/">regexr</a>
        </Row>
    </Container>
  </div>
);

OtherResources.propTypes = {};

OtherResources.defaultProps = {};

export default OtherResources;
