import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route } from 'react-router-dom';
import About from './components/About/About';
import Sidebar from './components/Sidebar/Sidebar';
import TextManipulator from './components/TextManipulator/TextManipulator';
import TextComparison from './components/TextComparison/TextComparison';
import OtherResources from './components/OtherResources/OtherResources';

function App() {


  return (
    <>
        <BrowserRouter>
            <Sidebar />
            <br/>
            {/* <Route path="/text-manipulator" component={TextManipulator}/> */}
            <Route exact path={["/", "/home", "/index", "/text-comparison"]} component={TextComparison}/>
            <Route path="/text-manipulator" component={TextManipulator} />
            <Route path="/other-resources" component={OtherResources}/>
            <Route path="/about" component={About}/>
        </BrowserRouter>
    </>
  );
}

export default App;
