import React, { useState } from 'react';
import {Container, Form, FormControl, Nav, Navbar, NavDropdown, Button, Offcanvas, Row, Col, Stack } from "react-bootstrap";
import { withRouter } from "react-router";
import "./Sidebar.css";
import { BrowserRouter, Link, Route } from 'react-router-dom';


const Sidebar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="Sidebar">
            <Navbar bg="dark" variant="dark" expand={false}>
                <Container fluid>
                    <Stack direction="horizontal" gap={3}>
                        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow}/>
                        <Navbar.Brand href="/">
                                <img
                                    src="/hammerTextIconRotated.png"
                                    width="40"
                                    height="40"
                                    className="d-inline-block align-top"
                                    alt="React Bootstrap logo"
                                />
                                {' '}
                                Text Tools
                        </Navbar.Brand>
                    </Stack>

                    <Offcanvas
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="start"
                    show={show} 
                    onHide={handleClose}
                    scroll={true}
                    backdrop={true}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
                        </Offcanvas.Header>

                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3 sidebar-items">
                                {/* 
                                    Todos:
                                    1. How can we keep sidebar open on the side but without using an offcanvas like angular text tools?
                                */}
                                <Nav.Link onClick={handleClose} as={Link} to="/about">About</Nav.Link>
                                <Nav.Link onClick={handleClose} as={Link} to="/text-comparison">Text Comparison</Nav.Link>
                                <Nav.Link onClick={handleClose} as={Link} to="/text-manipulator">Text Manipulation</Nav.Link> 
                                <Nav.Link onClick={handleClose} as={Link} to="/other-resources">Other Resources</Nav.Link>
                                
                            </Nav>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Container>
            </Navbar>
        </div>

    
    );
}
export default Sidebar
