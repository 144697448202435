import React from 'react';
import PropTypes from 'prop-types';
import './MyTextAreaWithLineNumber.css';
import TextAreaWithLineNumber from 'text-area-with-line-number';


const MyTextAreaWithLineNumber = (props) => (
  <div className="MyTextAreaWithLineNumber">
      <TextAreaWithLineNumber value={props.text} />
  </div>
);

MyTextAreaWithLineNumber.propTypes = {};

MyTextAreaWithLineNumber.defaultProps = {};

export default MyTextAreaWithLineNumber;
